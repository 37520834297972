<template>
  <div>
    <b-row>
      <b-col cols="3">
        <List
          ref="list"
          v-bind:stations="fallbackStations"
          v-on:add="addFallback"
          v-on:visible="showFallback"
          v-on:hidden="hideFallback"
          v-on:rename="renameFallback"
          v-on:remove="removeFallback"
        />
      </b-col>

      <b-col cols="9">
        <Map
          ref="map"
          v-if="station"
          v-bind:station="station"
          v-bind:fallback="fallbackStations"
          v-on:fallback-drawn="fallbackDrawn"
          v-on:fallback-modified="fallbackModified"
        />
      </b-col>
    </b-row>

    <AddFallbackStationDialog ref="addFallbackStationDialog" />
    <RenameFallbackStationDialog ref="renameFallbackStationDialog" />
  </div>
</template>

<script>
import lodash from "lodash";
import materialColor from "material-colors";
import parseColor from "parse-color";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";

import List from "@/view/pages/stations/fallback/List.vue";
import Map from "@/view/pages/stations/fallback/Map.vue";

import AddFallbackStationDialog from "@/view/content/dialogs/stations/AddFallbackStation.vue";
import RenameFallbackStationDialog from "@/view/content/dialogs/stations/RenameFallbackStation.vue";



var colorIndex = 0;

const rootColors = [
  materialColor.blue,
  materialColor.red,
  materialColor.green,
  // materialColor.amber,
  materialColor.purple,
  materialColor.deepOrange,
  materialColor.pink,
  materialColor.teal
];

const indexedColors = [
  ...rootColors.map(color => color[600]),
  ...rootColors.map(color => color.a200),
  ...rootColors.map(color => color[900]),

  materialColor.brown[700],
  materialColor.grey[900]
];

const assignedColors = {};



function getColorForName(name) {
  var color = assignedColors[name];
  if (!color) {
    color = indexedColors[colorIndex++ % indexedColors.length];

    assignedColors[name] = color;
  }

  return parseColor(color).rgb;
}

function mapStation(station) {
  const color = parseColor(materialColor.amber[600]).rgb;

  return {
    ...station,

    catchmentColor: [ ...color, 0.3 ],
    entranceColor:  [ ...color, 0.9 ],
    exitColor:      [ ...color, 0.5 ],
  };
}

function mapAvailableStation(station) {
  return {
    id:   station.id,
    name: station.name,
  };
}

function mapFallbackForDisplay(fallback) {
  const color = getColorForName(fallback.name);

  return {
    ...fallback,

    visible: true,

    catchmentColor: [ ...color, 0.8 ],
  };
}

function mapFallbackForUpdate(fallback) {
  return {
    id:   fallback.id,
    name: fallback.name,

    catchment: fallback.catchment,
  };
}



export default {
  name: "StationFallback",

  components: {
    List,
    Map,

    AddFallbackStationDialog,
    RenameFallbackStationDialog,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      station: null,

      fallbackStations:  [],
      availableStations: [],
    };
  },

  watch: {
    async fallbackStations(newValue, oldValue) {
      if (!oldValue || oldValue.length === 0) return;

      this.station.fallback = newValue.map(mapFallbackForUpdate);

      await api.stations.update(this.station);
    },
  },

  created() {
    colorIndex = 0; // HACK!
  },

  async mounted() {
    const station = await api.stations.get(this.id);
    this.station  = mapStation(station);

    this.$store.dispatch("setBreadcrumb", [
      { title: "Stații", route: { name: "stations" } },
      { title: `Rezerve stație ${this.station.name}` }
    ]);

    const stations = await api.stations.list();

    this.fallbackStations  = this.station.fallback.map(mapFallbackForDisplay);
    this.availableStations = stations.filter(s => s.id != this.station.id)
                                     .map(mapAvailableStation);
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** List Events ***** ***** ***** ***** ***** \*/
    async addFallback() {
      const fallback = await this.$refs.addFallbackStationDialog.show(this.availableStations);

      if (fallback) {
        const station = this.availableStations.find(s => s.id === fallback.id);

        if (station) {
          this.$refs.map.startDrawing({
            ...station,
            ...fallback,
          });
        }
      }
    },

    showFallback(fallback) {
      fallback.visible = true;
    },

    hideFallback(fallback) {
      fallback.visible = false;
    },

    async renameFallback(fallback) {
      const index = this.fallbackStations.findIndex(s => s.name === fallback.name);

      if (index !== -1) {
        const newName = await this.$refs.renameFallbackStationDialog.show(fallback);

        if (newName && newName.length) {
          const existing = this.fallbackStations.find(s => s.name === newName);

          if (existing) {
            notify.error(`Există deja o rezervă cu numele<br /><strong>${newName}</strong>.`);

            return;
          }

          this.station.fallback[index].name = newName;
          this.fallbackStations[index].name = newName;

          await api.stations.update(this.station);
        }
      }
    },

    async removeFallback(fallback) {
      const response = await this.confirmRemoveFallback(fallback);

      if (response) {
        const index = this.fallbackStations.findIndex(s => s.name === fallback.name);

        if (index !== -1) {
          this.fallbackStations.splice(index, 1);
        }
      }
    },

    /*\ ***** ***** ***** ***** ***** Map Events ***** ***** ***** ***** ***** \*/
    async fallbackDrawn(fallback) {
      this.fallbackStations.push(mapFallbackForDisplay(fallback));
    },

    async fallbackModified(fallback) {
      const index = this.fallbackStations.findIndex(s => s.name === fallback.name);

      if (index !== -1) {
        this.station.fallback[index].catchment = fallback.catchment;
        this.fallbackStations[index].catchment = fallback.catchment;

        await api.stations.update(this.station);
      }
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    confirmRemoveFallback(station) {
      const message = this.$createElement("p", { class: [ "mb-0", "font-size-h6", "text-center" ] }, [
        "Sunteți sigur că doriți să ștergeți rezerva ",
        this.$createElement("strong", station.name),
        "?"
      ]);

      const options = {
        centered: true,

        okTitle: "Șterge",
        okVariant: "danger",

        cancelTitle: "Renunță",

        footerClass: "justify-content-between",
      };

      return this.$bvModal.msgBoxConfirm(message, options);
    },
  },
};
</script>
