<template>
  <b-modal
    centered
    size="sm"
    ok-title="Continuă"
    cancel-title="Renunță"
    cancel-variant="danger"
    v-bind:title-html="`Adăugare stație de rezervă`"
    v-bind:ok-disabled="saveDisabled"
    v-on:ok="ok"
    v-on:hide="resolve(false)"
    v-model="visible"
  >
    <b-form-group label="Stație rezervă" label-for="station-id">
      <b-form-select v-model="selected">
        <b-form-select-option v-bind:value="null">Alegeți o stație</b-form-select-option>

        <b-form-select-option
          id="station-id"
          v-for="station in stations"
          v-bind:key="station.id"
          v-bind:value="station.id"
        >
          {{ station.name }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group label="Nume rezervă" label-for="station-name">
      <b-form-input id="station-name" v-model="name" v-bind:state="nameState" />
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      stations: [],

      name: null,
      selected: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    saveDisabled() {
      return !this.selected || !this.validName;
    },

    nameState() {
      if (this.selected) {
        return this.validName;
      } else {
        return null;
      }
    },

    validName() {
      return !!this.name && this.name.length > 0;
    },
  },

  watch: {
    selected(stationId) {
      const station = this.stations.find(s => s.id === stationId);

      if (station) {
        this.name = station.name;
      } else {
        this.name = "";
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(stations) {
      this.stations = stations;

      this.name     = null;
      this.selected = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.stations = [];

      this.name     = null;
      this.selected = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    ok() {
      const fallback = {
        id:   this.selected,
        name: this.name,
      };

      this.resolve(fallback);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        .form-group {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
