<template>
  <div id="fallback-list" class="card card-custom card-stretch">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder text-dark">Rezerve</h3>
      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <b-button variant="primary" size="sm" v-on:click="$emit('add')">Adaugă</b-button>
        </div>
      </div>
    </div>

    <Draggable
      class="card-body"
      handle=".handle"
      v-bind="dragOptions"
      v-bind:list="stations"
      v-on:start="dragging = true"
      v-on:end="dragging = false"
    >
      <transition-group type="transition" v-bind:name="dragTransition">
        <Station
          v-for="station in stations"
          v-bind:key="station.name"
          v-bind:station="station"
          v-on="$listeners"
        />
      </transition-group>
    </Draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

import Station from "@/view/pages/stations/fallback/Station.vue";

export default {
  components: {
    Draggable,
    Station,
  },

  props: {
    stations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dragging: false,

      dragOptions: {
        animation: 200,
        ghostClass: "ghost",
      },
    }
  },

  computed: {
    dragTransition() {
      return this.dragging ? null : "flip-list";
    },
  },
};
</script>

<style lang="scss">
#fallback-list {
  .card-header {
    border: 0;
  }

  .card-body {
    padding: 1rem 2.25rem;

    .station {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      &.ghost {
        opacity: 0.5;
      }
    }
  }
}
</style>
