<template>
  <div id="stations-map">
    <Map ref="map" v-bind:center="center" v-on:mounted="mapMounted">
      <vl-interaction-select
        v-if="!drawing"
        ident="modify"
        v-bind:filter="filterSelection"
        v-bind:toggle-condition="() => false"
        v-on:unselect="featureDeselected"
      />

      <!-- Base station -->
      <vl-layer-vector v-bind:z-index="1">
        <vl-source-vector v-bind:features="stationFeatures" />
        <vl-style-func v-bind:factory="styleStation" />
      </vl-layer-vector>

      <!-- Existing fallbacks -->
      <vl-layer-vector v-bind:z-index="2">
        <vl-source-vector ident="fallback" v-bind:features="fallbackFeatures" />
        <vl-style-func v-bind:factory="styleStation" />
      </vl-layer-vector>

      <vl-interaction-modify
        v-if="!drawing"
        source="modify"
        v-on:modifyend="featureModified"
      />

      <!-- Drawing new fallback -->
      <vl-layer-vector v-bind:z-index="3">
        <vl-source-vector ident="draw" v-bind:features.sync="drawnFeatures" />
      </vl-layer-vector>

      <vl-interaction-draw v-if="drawing && !drawingFinished" source="draw" type="polygon" />
      <vl-interaction-modify v-if="drawing" source="draw" />

      <vl-interaction-snap source="draw" v-bind:priority="10" />
      <vl-interaction-snap source="fallback" v-bind:priority="20" />
    </Map>

    <div class="drawing-message">
      <b-alert class="drawing-catchment" fade v-bind:show="drawing && !drawingFinished">
        Desenează zona de acoperire a rezervei <strong v-if="drawingStation">{{ drawingStation.name }}</strong>.

        <b-button-group size="sm">
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>

    <div class="drawing-message">
      <b-alert class="drawing-finished" fade v-bind:show="drawing && drawingFinished">
        Rezerva <strong v-if="drawingStation">{{ drawingStation.name }}</strong> este completă.

        <b-button-group size="sm">
          <b-button variant="primary" v-on:click="saveFallback">Salvează</b-button>
          <b-button variant="danger" v-on:click="cancelDrawing">Renunță</b-button>
        </b-button-group>
      </b-alert>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";

import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { createStyle } from 'vuelayers/lib/ol-ext'

import { default as Map, wkt } from "@/view/content/widgets/map/MapBase.vue";

const geoJsonFormat = new GeoJSON();

function getStyleForStation(feature) {
  const station = feature.get("station");

  const color = feature.get("color");
  const rgb   = color.slice(0, 3);
  const alpha = color[3];

  return createStyle({
    fillColor: color,

    strokeColor: [ ...rgb, alpha * 0.5 ],
    strokeWidth: 3,

    text: station.name,
    textFont: "Poppins, Helvetica, 'sans-serif'",
    textFontSize: "8pt",

    textFillColor: [ 0, 0, 255 ],
    textStrokeColor: [ 0, 0, 255, 0.25 ],
    textBackgroundFillColor: [ 255, 255, 255 ],
    textBackgroundStrokeColor: [ 0, 0, 255 ],

    textPadding: [ 1, 2, 0, 2 ],
    textOffsetY: -20,
  });
}

function convertGeoJsonToWkt(geoJson) {
  const feature = geoJsonFormat.readFeature(geoJson);

  return wkt.writeGeometry(feature.getGeometry());
}

function createFeature(station, isFallback, geometry, color, properties) {
  const feature = new Feature({ station, isFallback, geometry, color });

  feature.setProperties(properties);

  return geoJsonFormat.writeFeatureObject(feature);;
}

function createFeaturesForStation(station) {
  const catchmentGeo = wkt.readGeometry(station.catchment);
  const entranceGeo  = wkt.readGeometry(station.entrance);
  const exitGeo      = station.exit ? wkt.readGeometry(station.exit) : null;

  const catchmentFeature = createFeature(station, false, catchmentGeo, station.catchmentColor, { catchment: true });
  const entranceFeature  = createFeature(station, false, entranceGeo,  station.entranceColor,  { entrance:  true });
  const exitFeature      = station.exit ? createFeature(station, false, exitGeo,  station.exitColor, { exit: true }) : null;

  return {
    catchment: catchmentFeature,
    entrance:  entranceFeature,
    exit:      exitFeature,
  }
}

function createFeaturesForFallbackStation(fallback) {
  const geometry = wkt.readGeometry(fallback.catchment);
  const feature  = createFeature(fallback, true, geometry, fallback.catchmentColor, { catchment: true });

  return {
    shape: feature,
  }
}

function updateFallbackStationWithFeature(fallback, feature) {
  const geoJson = geoJsonFormat.writeFeatureObject(feature);
  const wkt     = convertGeoJsonToWkt(geoJson);

  fallback.catchment = wkt;
}

export default {
  components: {
    Map,
  },

  props: {
    station: {
      type: Object,
      required: true,
    },

    fallback: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      drawing: false,
      drawingStation: null,

      drawnFeatures: [],
    };
  },

  computed: {
    center() {
      const geometry = wkt.readGeometry(this.station.entrance)
      const extent   = geometry.getExtent();

      const x = extent[0] + (extent[2] - extent[0]) / 2;
      const y = extent[1] + (extent[3] - extent[1]) / 2;

      return [ x, y ];
    },

    stationFeatures() {
      const features = createFeaturesForStation(this.station);

      // Catchment, exit and entrance are drawn in this order to make the latter more visible.
      return [ features.catchment, features.exit, features.entrance ];
    },

    fallbackFeatures() {
      return this.fallback.filter(station => station.visible)
                          .map(createFeaturesForFallbackStation)
                          .map(station => station.shape);
    },

    drawingFinished() {
      return this.drawnFeatures.length === 1;
    },
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    startDrawing(station) {
      this.drawing        = true;
      this.drawingStation = station;
    },

    cancelDrawing() {
      this.drawing        = false;
      this.drawingStation = null;
      this.drawnFeatures  = [];
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    mapMounted() {
      const formHeight = jQuery("#fallback-list").height();
      const mapHeight  = Math.max(formHeight, 680);

      this.$refs.map.resize(mapHeight);
    },

    featureModified(event) {
      event.features.forEach(feature => {
        feature.setProperties({ modified: true });
      });
    },

    featureDeselected(feature) {
      if (feature.get("modified")) {
        const station = feature.get("station");

        updateFallbackStationWithFeature(station, feature);

        this.$emit("fallback-modified", station);
      }
    },

    saveFallback() {
      if (this.drawing) {
        const catchment = convertGeoJsonToWkt(this.drawnFeatures[0]);

        const fallback = {
          ...this.drawingStation,

          catchment,
        };

        this.cancelDrawing();
        this.$emit("fallback-drawn", fallback);
      }
    },

    styleStation() {
      return getStyleForStation;
    },

    filterSelection(feature) {
      // Only features of fallback stations are selectable.
      return feature.get("isFallback");
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/_mixins.scss";

#stations-map {
  position: relative;

  .drawing-message {
    top: 1rem;
    left: 50%;
    position: absolute;

    pointer-events: none;

    .alert {
      left: -50%;
      position: relative;

      .btn-group {
        margin-left: 0.5rem;

        button {
          pointer-events: auto;
        }
      }
    }

    .drawing-shape, .drawing-catchment {
      border-color: transparentize($color: $primary, $amount: 0.15);
      background-color: transparentize($color: $primary, $amount: 0.15);
    }

    .drawing-finished {
      border-color: transparentize($color: $success, $amount: 0.15);
      background-color: transparentize($color: $success, $amount: 0.15);
    }
  }
}
</style>
